import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a2950e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"absolute","font-size":"25pt","margin-left":"1%","color":"#00c89b"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeCalendar = _resolveComponent("PrimeCalendar")!
  const _component_PrimeDropDown = _resolveComponent("PrimeDropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode("EVENTS filter"),
    _createVNode(_component_PrimeCalendar, {
      modelValue: _ctx.selectedDateRange.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateRange.value) = $event)),
      selectionMode: "range",
      showIcon: "true"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_PrimeDropDown, {
      modelValue: _ctx.selectedClub,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedClub) = $event)),
      options: _ctx.clubs,
      optionLabel: "name",
      filter: true,
      showclear: true
    }, null, 8, ["modelValue", "options"])
  ]))
}